import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import Layout from '../../components/winter-2022/Layout';
import Results from '../../components/winter-2022/Results';
import "../../styles/Global.css"
import "../../styles/layout.module.css"

const ResultsPage = () => {

  return (
    <Layout type='secundary'>
      <SEO title="ISLA CORONA 2022" />
      <Results QR/>
    </Layout>
  );
}

export default ResultsPage;
